.content-section {
    height: 110vh;
}

.text-try {
    font-size: 200px;
}

/* .section-gamelist {
} */


.content-section::-webkit-scrollbar-thumb {
    /* background-color:white; */
    -webkit-tap-highlight-color: transparent;
    border-radius: .5rem;
}

.right-content {
    height: 110vh;
    overflow-y: auto;

}

.recent-winner-list {
    height: 20vh;
}

.primary-color-active {
    background-color: rgb(30, 44, 55);
    ;
}

/* .primary-color-active.actives{
    background-color: rgb(30, 44, 55);
} */