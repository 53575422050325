* {
    /* color: white; */
}

.module-logo {
    display: block;
    max-width: 130px;
}

.licensed {
    font-size: 8px;
}

.text-time {
    padding: 0px 20px 0px 0px;
    padding-bottom: 0px;
}

.navbar-nav .nav-item.dropdown:hover .dropdown-menu {
    display: block;
}

/* Your existing CSS styles... */

/* CSS for the slide-out transition */
.slide-out {
    transform: translateY(-100%);
    transition: transform 0.s ease;
}

/* Add transition properties to smoothly animate changes */
.fixed-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1030;
    transition: top 0.3s ease;
}

.dropdown-item:hover {
    background-color: #1E2C37;
    color: red;
}

.menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav_alternative {
    height: 85px;
}

.nav_alternative.display_none {
    display: none;
}