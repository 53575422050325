.inline-block {
    display: inline-block;
}

.h3-text {
    font-size: 140px;
}

.border-bottom {
    border-bottom-width: 1px;
    border-color: rgb(38, 54, 66);
}

.footer-context {
    font-size: 12px;
    line-height: 1.3;
}