.footer-bg-color,
.primary-color-active,
.primary-color-light {
    background-color: #1E2C37;
    /* color: white; */
}


.game-icons {
    height: 30px;
}

.navbar-fixed {
    position: fixed;
}

.sidenav-left {
    height: 100vh;
    /* position:static; */
}

.sidebar-accordion {
    background-color: #1E2C37;
}

.games-card,
.primary-color {
    background-color: #263642;
}


::-webkit-scrollbar {
    width: .30rem;
    background-color: #1E2C37;
    border-radius: .5rem;
    min-height: 80vh;
    /* margin-bottom: 20px; */
}

.sidenav-left::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: .5rem;
}

::-webkit-scrollbar-thumb:hover {
    /* background-color: ; */
}


.form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1;
    /* Firefox */
}

.form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: white;
}

.form-control::-ms-input-placeholder {
    /* Microsoft Edge */
    color: white;
}

.input-group>.form-control:focus,
.input-group>.form-floating:focus-within,
.input-group>.form-select:focus {
    z-index: 5;
    background-color: #263642;
    border-color: #263642;

    /* box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25); */
}

.form-control:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #263642;
    outline: none;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, .25);
}

.accordion-item  {
    border-color: #263642;
    /* color:#1E2C37; */
}
.btn-primary-color{
    background-color: #263642;
}

.accordion-button:not(.collapsed) {
    color: #ffffff;
    background-color: #e7f1ff;
    box-shadow: inset 0 -1px 0 rgba(0,0,0,.125);
    background-color: #263642;
}

